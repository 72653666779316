import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Menu from './Menu';
import { getUser } from '../../services/auth';
import { Logo } from '../../assets/icons/Logo';

export default function Nav({ open, setOpen }) {
  return (
    <NavContainer>
      <NavStyles>
        <ul>
          <LogoListItem>
            <Link to="/">
              <Logo size={30} />
              <CustomHeader>community</CustomHeader>
            </Link>
          </LogoListItem>

          <UsernameListItem>
            {getUser().firstName && `Hei, ${getUser().firstName}`}
          </UsernameListItem>
          <MenuListItem>
            <Menu open={open} setOpen={setOpen} />
          </MenuListItem>
        </ul>
      </NavStyles>
    </NavContainer>
  );
}

const CustomHeader = styled.h2`
  font-weight: 700;
  margin-left: 1rem;
  margin-bottom: 0;
  font-size: 1.9rem;
`;

const NavContainer = styled.div`
  background-color: var(--blue);
  min-height: var(--navHeight);
`;

export const NavStyles = styled.nav`
  background-color: var(--blue);
  max-width: var(--maxGridWidth);
  margin: 0 auto;
  padding: 0 2rem;

  ul {
    padding: 2rem;
    margin: 0;
    list-style: none;

    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    position: relative;

    @media (min-width: 1080px) {
      padding: 2rem 0;
    }
  }

  li {
    color: var(--white);

    a {
      color: var(--white);
      font-size: 2rem;
      text-decoration: none;

      &:hover {
        font-weight: bold;
      }
      &[aria-current='page'] {
        font-weight: bold;
      }
    }
  }
`;

const LogoListItem = styled.li`
  grid-column: 1;

  a {
    display: flex;
    align-items: center;
  }
`;

const UsernameListItem = styled.li`
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const MenuListItem = styled.li`
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
`;
