import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Logo = ({ size, className, variants }) => (
  <LogoSVG
    width={size}
    height={size}
    viewBox="0 0 70 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <path
      d="M36.7085 29.2521L69.0049 20.7368L68.7402 20.2073C65.7841 14.251 61.2396 9.17712 55.6363 5.60334C49.8124 1.94131 43.106 0 36.2232 0C16.2806 0 0 16.2364 0 36.2231C0 56.2099 16.2364 72.4463 36.2232 72.4463C43.106 72.4463 49.8123 70.505 55.5922 66.843C61.2396 63.2692 65.7399 58.2394 68.696 52.239L68.9608 51.7095L36.7085 43.1942V29.2521Z"
      fill="#73E3BF"
    />
    <path
      d="M29.9138 41.3852L46.7679 1.54417L46.2384 1.41181C45.5325 1.19121 44.7825 1.01472 44.0324 0.838241L43.6353 0.75L26.8253 40.5469L0.132248 33.5317L0.0881281 34.1053C0.0440074 34.8112 0.0440063 35.5172 0.0440063 36.2231V36.7967L14.8245 40.6793L7.41217 58.1511L7.58866 58.3717C8.11811 59.0335 8.64756 59.6953 9.177 60.313L9.70645 60.8866L17.9129 41.5176L67.4605 54.6215L67.637 54.3126C67.99 53.6949 68.3871 52.989 68.6959 52.2831L68.9606 51.7536L29.9138 41.3852Z"
      fill="white"
    />
    <path
      d="M36.7086 33.8848V43.1942L46.5034 45.7974C46.5034 44.8708 46.3269 43.9443 46.0181 42.9295C44.7827 39.0469 41.1648 35.782 36.7086 33.8848Z"
      fill="#006EE5"
    />
    <path
      d="M28.2373 32.1199C28.105 32.1199 28.0167 32.1199 27.8844 32.1199C22.5458 32.2082 18.2661 34.4583 16.413 37.8115L26.8255 40.547L30.3551 32.2082C29.6492 32.1641 28.9433 32.1199 28.2373 32.1199ZM33.5318 32.8259L29.9139 41.3853L46.5475 45.7532L36.7527 43.1501V33.8848C35.6938 33.4436 34.6349 33.0906 33.5318 32.8259ZM17.9131 41.5177L16.1483 45.6209C16.9865 47.5622 18.3984 49.327 20.2956 50.8713L36.0908 65.1223L44.915 50.6948C45.3121 50.1212 45.665 49.5476 45.9298 48.9299L17.9131 41.5177Z"
      fill="#69CCAB"
    />
    <path
      d="M30.3551 32.2522L26.8254 40.5911L16.4129 37.8556C15.5305 39.4439 15.1334 41.297 15.4864 43.3265C15.6188 44.1207 15.8394 44.9149 16.1923 45.6649L17.9131 41.5617L45.9297 48.974C46.3268 48.0033 46.5474 46.9444 46.5474 45.8414L29.9139 41.4735L33.5318 32.914C32.517 32.517 31.414 32.3405 30.3551 32.2522Z"
      fill="#E5E5E5"
    />
    <path
      d="M23.031 47.0327L36.2231 65.0781L49.4152 47.0327H23.031Z"
      fill="white"
    />
    <path
      d="M53.2978 36.2231C53.2978 26.8254 45.6649 19.1925 36.2672 19.1925C26.8695 19.1925 19.2366 26.8254 19.2366 36.2231C19.2366 45.6208 26.8695 53.2537 36.2672 53.2537C45.6649 53.2537 53.2978 45.6208 53.2978 36.2231Z"
      fill="#FF6696"
    />
  </LogoSVG>
);

export const LogoSVG = styled(motion.svg)``;
