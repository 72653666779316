import { createGlobalStyle } from 'styled-components';
import '@fontsource/poppins';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

const Typography = createGlobalStyle`
  html {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
  }

  h1 {
    font-size: clamp(2.5rem, 3vw, 5rem)
  }

  h2 {
    font-size: clamp(2rem, 3vw, 3rem);
    margin-bottom: 1rem;
  }

  a {
    color: var(--black);
    text-decoration-color: inherit;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
