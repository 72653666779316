import Cookies from 'js-cookie';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {};

const setUser = (user) => localStorage.setItem('user', JSON.stringify(user));

const setCompanies = (companies) =>
  localStorage.setItem('companies', JSON.stringify(companies));

const setChoosenCompanyId = (choosenCompanyId) =>
  localStorage.setItem('choosenCompanyId', JSON.stringify(choosenCompanyId));

const setChoosenCompanyName = (choosenCompanyName) =>
  localStorage.setItem(
    'choosenCompanyName',
    JSON.stringify(choosenCompanyName)
  );

const setChoosenCompanyCategory = (choosenCompanyCategory) =>
  localStorage.setItem(
    'choosenCompanyCategory',
    JSON.stringify(choosenCompanyCategory)
  );

const setChoosenCompanyCommunityId = (choosenCompanyCommunityId) =>
  localStorage.setItem(
    'choosenCompanyCommunityId',
    JSON.stringify(choosenCompanyCommunityId)
  );

export const getConfigurationInfo = async () => {
  try {
    const url = `https://api.vipps.no/access-management-1.0/access/.well-known/openid-configuration`;
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: '*/*',
      },
    });
    const data = await res;
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.firstName;
};

export const logout = (callback) => {
  setUser({});
  setCompanies({});
  setChoosenCompanyId({});
  setChoosenCompanyName({});
  setChoosenCompanyCategory({});
  setChoosenCompanyCommunityId({});

  Cookies.remove('accessToken', { path: '/' });
  Cookies.remove('firstName', { path: '/' });
  Cookies.remove('id', { path: '/' });
  Cookies.remove('refreshToken', { path: '/' });

  callback();
};
