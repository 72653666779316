import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Burger } from './Burger';
import Drawer from './Drawer';

export default function Menu({ open, setOpen }) {
  // calls the useRef hook. The ref is set to the App div
  const node = useRef();

  // creating a custom hook based on the useEffect hook.
  // Pass in a ref and a handler. The handler is a function to run
  // if the mouse clicks on something outside of the ref.
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      // defines a listener. This is a function which takes an event
      // and acts on it.
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      // adds the listener function to the document
      document.addEventListener('mousedown', listener);

      // removes the listener function when the component is unmounted
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    }, [ref, handler]);
  };

  // calls the custom hook passing in the ref
  useOnClickOutside(node, () => {
    setOpen(false);
  });

  return (
    <MenuStyles ref={node}>
      <Burger open={open} setOpen={setOpen} />
      <Drawer open={open} setOpen={setOpen} />
    </MenuStyles>
  );
}

const MenuStyles = styled.div`
  display: flex;
`;
