// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-coming-soon-jsx": () => import("./../../../src/pages/coming-soon.jsx" /* webpackChunkName: "component---src-pages-coming-soon-jsx" */),
  "component---src-pages-community-builder-jsx": () => import("./../../../src/pages/community-builder.jsx" /* webpackChunkName: "component---src-pages-community-builder-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-guidelines-jsx": () => import("./../../../src/pages/guidelines.jsx" /* webpackChunkName: "component---src-pages-guidelines-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-what-does-it-solve-jsx": () => import("./../../../src/pages/what-does-it-solve.jsx" /* webpackChunkName: "component---src-pages-what-does-it-solve-jsx" */)
}

