import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Helmet } from 'react-helmet';
import Nav, { NavStyles } from './Menu/Nav';
import Footer, { FooterStyles } from './Footer';

import Typography from '../styles/Typograhpy';
import GlobalStyles from '../styles/GlobalStyles';

import { UserProvider } from '../context/UserContext';
import { CreateAdvertisementProvider } from '../context/CreateAdvertisementContext';

import 'normalize.css';

export default function Layout({ children, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <UserProvider>
      <CreateAdvertisementProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Community</title>
          <link rel="canonical" href="https://www.community.no/" />
          <meta
            name="description"
            content="Hele Norges nye nærmiljø-plattform"
          />
          <meta property="og:title" content="Community" />
          <meta
            property="og:description"
            content="Hele Norges nye nærmiljø-plattform"
          />
          <meta charset="UTF-8" />
          <meta property="og:site_name" content="Community" />
          <html lang="no" />
          <meta name="theme-color" content="#0071EB" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Typography />
        <GlobalStyles open={open} />
        <ContentStyles
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.2,
            ease: 'easeIn',
          }}
        >
          <Nav url={props.location.pathname} open={open} setOpen={setOpen} />
          <MainContent open={open}>{children}</MainContent>
          <Footer />
        </ContentStyles>
      </CreateAdvertisementProvider>
    </UserProvider>
  );
}

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: ${({ open }) => (open ? '150' : '200')};

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const ContentStyles = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (min-width: 768px) {
    grid-template-rows: 60px auto minmax(120px, 180px);
    grid-template-columns: 1fr auto 1fr;
  }

  ${NavStyles} {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  ${FooterStyles} {
    grid-column: 1 / -1;
    grid-row: 3;
  }
`;
