import React from 'react';
import styled from 'styled-components';

export const Burger = ({ open, setOpen }) => (
  <StyledBurger
    aria-label="menu navigation button"
    open={open}
    onClick={() => setOpen(!open)}
  >
    <div />
    <div />
    <div />
  </StyledBurger>
);

export const StyledBurger = styled.button`
  cursor: pointer;
  z-index: 1000;
  border: none;
  position: relative;
  height: 30px;
  width: 35px;

  background-color: var(--lightBlue);
  border-radius: 6px;
  padding: 7px;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 2px;
    background: var(--white);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: absolute;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(37deg)' : 'rotate(0)')};
      width: ${({ open }) => (open ? '2.5rem' : '2rem')};
      top: 7px;
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      top: 14px;
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-37deg)' : 'rotate(0)')};
      width: ${({ open }) => (open ? '2.5rem' : '2rem')};
      bottom: 7px;
    }
  }
`;
