import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

export default function Footer() {
  return (
    <FooterContainer>
      <FooterStyles>
        <ul>
          <li>
            <CustomLink to="/contact">Kontakt</CustomLink>
          </li>
          <li>
            <CustomLink to="/privacy-policy">Personvern</CustomLink>
          </li>
          <li>
            <CustomLink to="/guidelines">Retningslinjer</CustomLink>
          </li>
          <li>
            Org.nr: 918624570 Copyright © {new Date().getFullYear()}
            Community.no
          </li>
        </ul>
        <span />
      </FooterStyles>
    </FooterContainer>
  );
}
const FooterContainer = styled.div`
  background-color: var(--blue);
  z-index: 200;
  min-height: var(--footerHeight);
`;

export const FooterStyles = styled.footer`
  color: var(--white);
  padding: 3.8rem 2rem 3.4rem 2.2rem;
  max-width: var(--gridWidth);
  margin: 0 auto;

  @media (min-width: 1080px) {
    padding: 3.8rem 0 3.4rem 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    font-size: 1.4rem;
    font-weight: 300;
    &:last-of-type {
      margin-top: 1rem;
    }
  }
`;

const CustomLink = styled(Link)`
  margin: 0 0 1rem 0;
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 300;
  text-decoration: none;
  line-height: 2.4rem;
  &:hover {
    border-bottom: 1px solid var(--white);
  }
`;
