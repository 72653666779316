import React, { createContext, useContext, useMemo, useState } from 'react';
import { isBrowser } from '../services/auth';

const CreateAdvertisementContext = createContext();

export const useCreateAdvertisementContext = () => {
  const value = useContext(CreateAdvertisementContext);
  if (!value) {
    throw new Error(
      'You have to use useCreateAdvertisementContext within CreateAdvertisementProvider'
    );
  }
  return value;
};

export const CreateAdvertisementProvider = ({ children }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [img, setImg] = useState({ path: '', byteArray: '' });
  const [previewCroppedImage, setPreviewCroppedImage] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [externalUrl, setExternalUrl] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [advertisementType, setAdvertisementType] = useState(1);

  // DUMMY CONTENT
  // const [title, setTitle] = useState('Alle store pizzaer 99,-');
  // const [description, setDescription] = useState(
  //   'Vi kjører et åpningstilbud på alle store pizzaer inkludert drikke denne helgen!'
  // );
  // const [img, setImg] = useState({
  //   path: 'https://media.istockphoto.com/photos/tasty-pepperoni-pizza-and-cooking-ingredients-tomatoes-basil-on-black-picture-id1083487948?k=6&m=1083487948&s=612x612&w=0&h=lK-mtDHXA4aQecZlU-KJuAlN9Yjgn3vmV2zz5MMN7e4=',
  // });
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [discountCode, setDiscountCode] = useState('1234ABC');
  // const [offerType, setOfferType] = useState(1);

  const [advertisements, setAdvertisements] = useState(
    (isBrowser() && JSON.parse(localStorage.getItem('advertisements'))) || []
  );

  const [currentAdvertisement, setCurrentAdvertisement] = useState({});
  const [loadingAdvertisements, setLoadingAdvertisements] = useState(false);

  const [error, setError] = useState(false);

  const AdvertisementValue = useMemo(
    () => ({
      title,
      description,
      img,
      startDate,
      endDate,
      discountCode,
      advertisementType,
      advertisements,
      currentAdvertisement,
      loadingAdvertisements,
      error,
      externalUrl,
      previewCroppedImage,
      setPreviewCroppedImage,
      setAdvertisements,
      setCurrentAdvertisement,
      setLoadingAdvertisements,
      setError,
      setTitle,
      setDescription,
      setImg,
      setStartDate,
      setEndDate,
      setDiscountCode,
      setAdvertisementType,
      setExternalUrl,
    }),
    [
      title,
      description,
      img,
      startDate,
      endDate,
      discountCode,
      advertisementType,
      advertisements,
      currentAdvertisement,
      loadingAdvertisements,
      error,
      externalUrl,
      previewCroppedImage,
      setPreviewCroppedImage,
      setAdvertisements,
      setCurrentAdvertisement,
      setLoadingAdvertisements,
      setError,
      setTitle,
      setDescription,
      setImg,
      setStartDate,
      setEndDate,
      setDiscountCode,
      setAdvertisementType,
      setExternalUrl,
    ]
  );

  return (
    <CreateAdvertisementContext.Provider value={AdvertisementValue}>
      {children}
    </CreateAdvertisementContext.Provider>
  );
};
