import React, { createContext, useContext, useMemo, useState } from 'react';
import { isBrowser } from '../services/auth';

const UserContext = createContext();

export const useUserContext = () => {
  const value = useContext(UserContext);
  if (!value) {
    throw new Error('You have to use useUserContext within UserProvider');
  }
  return value;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    id:
      (isBrowser() &&
        localStorage.getItem('user') !== null &&
        JSON.parse(localStorage.getItem('user')).id) ||
      '',
    firstName:
      (isBrowser() &&
        localStorage.getItem('user') !== null &&
        JSON.parse(localStorage.getItem('user')).firstName) ||
      '',
    accessToken:
      (isBrowser() &&
        localStorage.getItem('user') !== null &&
        JSON.parse(localStorage.getItem('user')).accessToken) ||
      '',
    refreshToken:
      (isBrowser() &&
        localStorage.getItem('user') !== null &&
        JSON.parse(localStorage.getItem('user')).refreshToken) ||
      '',
  });

  const [companies, setCompanies] = useState(
    (isBrowser() && JSON.parse(localStorage.getItem('companies'))) || []
  );

  const [loadingUser, setLoadingUser] = useState(false);

  const [choosenCompany, setChoosenCompany] = useState({
    id: (isBrowser() && localStorage.getItem('choosenCompanyId')) || {},
    name: (isBrowser() && localStorage.getItem('choosenCompanyName')) || {},
    category:
      (isBrowser() && localStorage.getItem('choosenCompanyCategory')) || {},
    communityId:
      (isBrowser() && localStorage.getItem('choosenCompanyCommunityId')) || {},
    thumbnailUrl:
      (isBrowser() && localStorage.getItem('choosenCompanyThumbnailUrl')) || {},
  });

  const [accessToken, setAccessToken] = useState('');

  const UserValue = useMemo(
    () => ({
      user,
      loadingUser,
      choosenCompany,
      accessToken,
      companies,
      setUser,
      setLoadingUser,
      setChoosenCompany,
      setAccessToken,
      setCompanies,
    }),
    [user, loadingUser, choosenCompany, accessToken, companies]
  );

  return (
    <UserContext.Provider value={UserValue}>{children}</UserContext.Provider>
  );
};
