import { Link, navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { isLoggedIn, logout } from '../../services/auth';
import { useUserContext } from '../../context/UserContext';

export default function Drawer({ open, setOpen }) {
  const { companies, choosenCompany } = useUserContext();

  return (
    <DrawerContainer open={open}>
      <StyledDrawer>
        <ul>
          <li>
            <Link onClick={() => setOpen(!open)} to="/">
              Hjem
            </Link>
          </li>

          <li>
            <Link onClick={() => setOpen(!open)} to="/about-us">
              Om oss
            </Link>
          </li>

          <li>
            <Link onClick={() => setOpen(!open)} to="/what-does-it-solve">
              Hva løser community plattformen?
            </Link>
          </li>

          <li>
            <Link onClick={() => setOpen(!open)} to="/community-builder">
              Samfunnsbygger rollen
            </Link>
          </li>

          <li>
            <Link
              onClick={() => setOpen(!open)}
              to={`/app/my-page/${choosenCompany.id}`}
            >
              For annonsører
            </Link>
          </li>

          <li>
            <Link onClick={() => setOpen(!open)} to="/contact">
              Kontakt
            </Link>
          </li>

          <li>
            {isLoggedIn() && companies?.length > 1 ? (
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  setOpen(!open);
                  navigate(`/app/my-companies`);
                }}
              >
                Bytt selskap
              </a>
            ) : null}
          </li>

          <li>
            {isLoggedIn() ? (
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  setOpen(!open);
                  logout(() => navigate(`/`));
                }}
              >
                Logg ut
              </a>
            ) : null}
          </li>
        </ul>
      </StyledDrawer>
    </DrawerContainer>
  );
}

const DrawerContainer = styled.div`
  background: var(--blue);
  width: 100vw;
  z-index: ${({ open }) => (open ? '200' : '150')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: absolute;
  top: 0;
  right: -2rem;
  transition: 0.5s ease-in-out;
  text-align: left;
  opacity: ${({ open }) => (open ? '1' : '0')};
  padding: 3rem;
  overflow-y: hidden;

  @media (min-width: 1024px) {
    width: 400px;
  }
`;

const StyledDrawer = styled.nav`
  max-width: var(--gridWidth);
  margin: 0 4rem 0 4rem;

  ul {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  li {
    margin-bottom: 2rem;

    a {
      font-size: 2rem;
      font-weight: bold;
      line-height: 3rem;
      color: var(--white);
      text-decoration: none;
      transition: color 0.3s linear;

      &:hover {
        border-bottom: 2px solid white;
      }
    }
  }

  @media (min-width: 1024px) {
  }
`;
