import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #0071EB;
    --lightBlue: #218BFE;
    --lighestBlue: rgba(0, 113, 235, 0.2);
    --black: #000000;
    --grey: #8A8A8A;
    --darkGrey: #3C3C43;
    --lightGrey: #EFF2F9;
    --lighterGrey: rgba(138, 138, 138, 0.2);
    --pink: #FF6696;
    --seaGreen: #74E2BE;
    --green: #4D927B;
    --white: #fff;
    --red: #FF4949;

    --navHeight: 70px;
    --footerHeight: 175px;

    // Grid
    --gridWidth: 1024px;
    --maxGridWidth: 1680px;
    --maxTextWidth: 630px;

    // Card config
    --maxWidthCardImage: 550px;
    --maxHeightCardImage: 260px;
  }

  * {
    box-sizing: border-box;
  }

  html {
      font-size: 10px;
      min-height: 100%;
      overflow-y: ${({ open }) => (open ? 'scroll' : 'initial')};
    }

    body {
      font-size: 2rem;
      min-height: 100%;
      position: ${({ open }) => (open ? 'fixed' : 'initial')};
      right: 0;
      left: 0;
      top:0;
      bottom: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  div[role="group"][tabindex] {
    flex: 1;
    // Other rules that don't break a11y 😊
  }

  img {
    max-width: 100%;
  }

  p {
    line-height: 2rem;
    font-size: 1.6rem;
  }
`;

export default GlobalStyles;
